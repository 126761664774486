<template>
   <!--====== FEATURE ONE PART START ======-->
   <section class="features-area features-one">
      <div class="container">
         <div class="row justify-content-center">
            <div class="col-lg-6">
               <div class="section-title text-center">
                  <h3 class="title">OUR SERVICES</h3>
                  <div class="section-divider mt-3"></div>
                  <p class="text">
                     Stop wasting time and money designing and managing a website
                     that doesn’t get results. Happiness guaranteed!
                  </p>
               </div>
               <!-- row -->
            </div>
         </div>

         <div class="wrapper-con  d-flex justify-content-center flex-wrap mt-5">
            <div class="single-card mx-3 mb-5 col-lg-4 col-md-7 col-11 " v-for="(item, index) in serviceList"
               :key="item" :class="{ 'rotate-card': item.isRotated }">

               <div class="front  p-3" @click="rotateCard(index)">
                  <div class="features-icon">
                     <img :src="item.image_url" alt="image" width="160">
                  </div>

                  <div>
                     <h1 class="card-title">{{ item.title }}</h1>
                  </div>

               </div>

               <div class="back p-4" @click="rotateCard(index)">
                  <div class="content">

                     <div>
                        <p class="description">{{ item.short_description }}</p>
                     </div>

                     <div class="read_more_btn btn btn-primary " @click="goToServiceDetail(item.id)">
                        Read More
                     </div>

                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- container -->
   </section>
   <!--====== FEATURE ONE PART ENDS ======-->
</template>



<script>
// import axios from 'axios';
import { mapState, mapActions } from 'vuex';
export default {
   data() {
      return {
         isCardRotated: false,
      };
   },

   computed: {
      ...mapState('services', ['serviceList']),
      filteredItems() {
         return this.serviceList; // Filtered list
      }
   },
   created() {
      this.fetchServices(); // Call the function to fetch data from the API
   },
   methods: {
      ...mapActions('services', ['rotateCard', 'fetchServices', 'fetchServiceDetail']),

      rotateCard(index) {
         this.$store.dispatch('services/rotateCard', index);   // Rotate the card
      },
      goToServiceDetail(service_id) {
         this.fetchServiceDetail(service_id).then(() => {
            this.$router.push({ name: 'service_detail', params: { id: service_id } });
         });
         localStorage.setItem('selectedServiceId', service_id);
      },
   },

}

</script>

<style scoped>
.section-divider {
   width: 330px !important;
   /* Full width of the container */
   height: 5px;
   margin: 0 auto;
   /* Height of the divider, adjust as needed */
   background-image: url('https://magency.s3.eu-central-1.amazonaws.com/images/banner.jpg');
   /* The path to your banner image */
   background-size: cover;
   /* Cover ensures that the banner covers the div area */
   background-repeat: no-repeat;
   background-position: center;
   /* Center the image within the div */
}

.read_more_btn {

   position: relative !important;
   bottom: -20px !important;
   /* Adjust as needed to position the button from the bottom */
   /* left: 10px !important; */
}

.features-area {
   background: transparent !important;
}

.single-card {
   height: 265px;
   width: 300px;

   /* margin: 0 auto; */
   /* position: relative; */
}

.wrapper-con {
   perspective: 900px;
}

.single-card {
   text-align: center;
   transition: all 0.9s cubic-bezier(0.7, -0.5, 0.3, 1.8);
   transform-style: preserve-3d;
}



.rotate-card {
   transform: rotateY(180deg);
   /* Rotate the card 180 degrees on the Y-axis */
}

.front,
.back {
   width: 300px;
   position: relative;
   top: 0;
   left: 0;
   -webkit-backface-visibility: hidden;
   backface-visibility: hidden;
}

.front {
   cursor: pointer;
   height: 100%;
   background: transparent;
   -webkit-backface-visibility: hidden;
   backface-visibility: hidden;
   border-radius: 15px;
   background-size: cover;
   background-position: center center;
}


.front:hover {
   background: #4a2c8221 !important;
}


.front .card-title {
   color: #ffffff;
   font-size: 17px !important;
   margin-top: 10px !important;
}

.back {
   transform: rotateY(180deg);
   position: absolute;
   border-radius: 15px;
   height: 100%;
   background: #4a2c8221;
   color: #ffffff !important
}






.content .description {
   color: #ffffff;
   font-size: 20px !important;
}

.socials {
   margin-top: 20%;
}

.socials i {
   font-size: 30px;
   margin: 0 10px;
}



/* ===== Buttons Css ===== */
.features-style-one .primary-btn-outline {
   border-color: #4A2C82;
   color: #4A2C82;
}

.features-style-one .active.primary-btn-outline,
.features-style-one .primary-btn-outline:hover,
.features-style-one .primary-btn-outline:focus {
   background: var(--primary-dark);
   color: var(--white);
}

.features-style-one .deactive.primary-btn-outline {
   color: var(--dark-3);
   border-color: var(--gray-4);
   pointer-events: none;
}

/*===========================
  Features One CSS
===========================*/
.features-one {
   background-color: #ffffff;
   padding-top: 120px;
   padding-bottom: 120px;
}

.features-one .section-title {
   padding-bottom: 10px;
}

.features-one .title {
   font-size: 44px;
   font-weight: 600;
   color: #ffffff;
   line-height: 55px;
}

@media (max-width: 767px) {
   .features-one .title {
      font-size: 30px;
      line-height: 35px;
   }
}

.features-one .text {
   font-size: 16px;
   line-height: 24px;
   color: rgb(255, 255, 255);
   margin-top: 24px;
}

.features-style-one {
   background-color: white !important;
   padding: 40px 20px;
   margin-top: 40px;

   border-radius: 4px;
   transition: all 0.3s;
}



.features-style-one .features-icon {
   position: relative;
   display: inline-block;
   z-index: 1;
   height: 160px;
   width: 160px;
   line-height: 100px;
   text-align: center;
   font-size: 40px;
   color: #4A2C82;
   /* border: 2px solid rgba(187, 187, 187, 0.192); */
   border-radius: 50%;
   -webkit-transition: all 0.3s ease-out 0s;
   -moz-transition: all 0.3s ease-out 0s;
   -ms-transition: all 0.3s ease-out 0s;
   -o-transition: all 0.3s ease-out 0s;
   transition: all 0.3s ease-out 0s;
}

@media (max-width: 767px) {
   .features-style-one .features-icon {
      height: 70px;
      width: 70px;
      line-height: 70px;
      font-size: 35px;
   }
}



.features-style-one .features-content {
   margin-top: 24px;
}

.features-style-one .features-title {
   font-size: 23px;
   line-height: 35px;
   font-weight: 600;
   color: var(--black);
   -webkit-transition: all 0.3s ease-out 0s;
   -moz-transition: all 0.3s ease-out 0s;
   -ms-transition: all 0.3s ease-out 0s;
   -o-transition: all 0.3s ease-out 0s;
   transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 767px) {
   .features-style-one .features-title {
      font-size: 22px;
   }


}

.features-style-one .text {
   color: var(--dark-3);
   margin-top: 16px;
}

.features-style-one .features-btn {
   margin-top: 32px;
}


@media only screen and (max-width: 767px) {
   .features-one {
      padding-bottom: 0px !important;
   }
}
</style>