import axios from 'axios';

// services.js
const state = {
    serviceList: [],
    selectedService: null,
    // currentItem: null, // this is for the current item in the list
};

const mutations = {
    setServiceList(state, services) {
        state.serviceList = services;
    },
    setSelectedService(state, service) {
        state.selectedService = service;
    },

    SET_CURRENT_ITEM(state, item) {
        state.currentItem = item;
    },
    TOGGLE_ROTATION(state, index) {
        state.serviceList[index].isRotated = !state.serviceList[index].isRotated;
    },

};

const actions = {
    async fetchServices({commit}) {
        try {
            const response = await axios.get('https://admin.magency.me/api/v1/api-services?per_page=');
            commit('setServiceList', response.data.data);
        } catch (error) {
            console.error('Error fetching services:', error);
        }
    },
    async fetchServiceDetail({ commit }, service_id) {
        try {
            const response = await axios.get(`https://admin.magency.me/api/v1/api-services/${service_id}`);
            commit('setSelectedService', response.data.data); // Update state with service detail
        } catch (error) {
            console.error(`Error fetching service detail with ID ${service_id}:`, error);
        }
    },
    async fetchItemDetail({ commit }, item_id) {
        try {
            const response = await axios.get(`https://admin.magency.me/api/v1/api-services/item/${item_id}`);
            commit('SET_CURRENT_ITEM', response.data.data);
        } catch (error) {
            console.error(`Error fetching item detail with ID ${item_id}:`, error);
        }
    },

    rotateCard({ commit }, index) {
        commit('TOGGLE_ROTATION', index);
    },

};

const getters = {
    selectedService: (state) => state.selectedService, // Use this to access detailed service data
    serviceList: (state) => state.serviceList,


};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};