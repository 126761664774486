<template>
	<appHeader v-if="showComponent" />
	<header class="fix header d-none">

		<div class="abs mobile-background"></div>

		<a class="header-logo" name="Home" href="/">
			<img src="../assets/logo.png" width="120" alt="logo">
		</a>

		<div class="mobile-btn">
			<div class="abs f-center icon open"><svg viewbox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect width="18" height="4" fill="#F24139"></rect>
					<rect y="7" width="18" height="4" fill="#F24139"></rect>
					<rect y="14" width="18" height="4" fill="#F24139"></rect>
				</svg>
			</div>
			<div class="abs f-center icon close"><svg viewbox="0 0 16 16" fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<rect x="3.05078" y="0.222656" width="18" height=" 4" transform="rotate(45 3.05078 0.222656)"
						fill="#F24139"></rect>
					<rect width="18" height="4"
						transform="matrix(-0.707107 0.707107 0.707107 0.707107 12.9492 0.222656)" fill="#F24139"></rect>
				</svg>
			</div>
		</div>

		<div class="main-menu">
			<nav class="first">
				<router-link class="item" data-name="Vision & Mission" to="/">
					<span class="title">Home</span>
				</router-link>
				<router-link class="item" data-name="Our Platform" to="/services">
					<span class="title">Services</span>
				</router-link>
			</nav>
			<nav class="second">
				<router-link class="c-btn item" to="/works">
					<span class="title">Work</span>
				</router-link>
				<router-link class="c-btn item" to="/about">
					<span class="title">About</span>
				</router-link>
				<router-link class="c-btn item" to="/contact">
					<span class="title">Contact</span>
				</router-link>
			</nav>
		</div>
	</header>

	<div class="sticky">
		<a href="https://www.google.com/partners/agency?id=6967373952" target="_blank">
			<img src="https://www.gstatic.com/partners/badge/images/2024/PartnerBadgeClickable.svg" />
		</a>
	</div>

	<div id="container" role="main">
		<div id="home" class="page p-home hidden">
			<div class="fixed-wrapper"></div>
			<div class="js-scroll">
				<main class="page-wrapper">
					<section class="p-home__header" data-prllx="1" data-p-top data-p-no-mask>
						<div class="abs p-home__intro" data-p-elt>
							<div class="intro-wrapper">
								<video class="lazy abs intro-loop-mobile" muted playsinline loop>
									<source data-src="https://magency.s3.eu-central-1.amazonaws.com/Mobile-Loop.mp4"
										type="video/mp4">
									</source>
								</video>
								<video class="lazy abs intro-loop-desktop" muted playsinline loop>
									<source data-src="https://magency.s3.eu-central-1.amazonaws.com/Desktop-Loop.mp4"
										type="video/mp4">
									</source>
								</video>
								<div class="abs gradient"></div>
								<video class="lazy abs intro-mobile" muted playsinline>
									<source
										data-src="https://magency.s3.eu-central-1.amazonaws.com/Mobile-Website+Animation+Reel+Short.mp4"
										type="video/mp4">
									</source>
								</video>
								<video class="lazy abs intro-desktop" muted playsinline>
									<source
										data-src="https://magency.s3.eu-central-1.amazonaws.com/Desktop-Website+Animation+Short.mp4"
										type="video/mp4">
									</source>
								</video>
							</div>
						</div>
					</section>

					<!-- 3 image -->

					<div class="webgl-container2">
						<!-- Your WebGL fluid simulation code here -->
						<canvas id="cursor-canvas2"></canvas>

						<section class="p-home__target">
							<div class="photos">
								<div class="abs photo photo-1">
									<div class="wrapper-anim">
										<div class="wrapper">
											<div class="image">
												<picture class="r r--resp" style="padding-bottom:100%" data-ratio="1">
													<img class="lazy"
														src="https://magency.s3.eu-central-1.amazonaws.com/images/MA/ma11.jpg"
														data-sizes="(min-width: 750px) 40vw, 100vw" alt="">
												</picture>
											</div>
										</div>
									</div>
								</div>
								<div class="abs photo photo-2">
									<div class="wrapper-anim">
										<div class="wrapper">
											<div class="image">
												<picture class="r r--resp" style="padding-bottom:100%" data-ratio="1">
													<img class="lazy"
														src="https://magency.s3.eu-central-1.amazonaws.com/images/MA/ma7.jpg"
														data-sizes="(min-width: 750px) 40vw, 100vw" alt="">
												</picture>
											</div>
										</div>
									</div>
								</div>
								<div class="abs photo photo-3">
									<div class="wrapper-anim">
										<div class="wrapper">
											<div class="image">
												<picture class="r r--resp" style="padding-bottom:100%" data-ratio="1">
													<img class="lazy"
														src="https://magency.s3.eu-central-1.amazonaws.com/images/MA/ma14.jpg"
														data-sizes="(min-width: 750px) 40vw, 100vw" alt="">
												</picture>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="content_">
								<h2 class="">
									REACH THE <br>
									RIGHT CROWD <br>
									WITH INNOVATIVE <br>
									MEDIA SOLUTIONS
								</h2>
							</div>

						</section>
					</div>

					<hero />
					<div class="banner-image2"></div>
					<div class="banner-image"></div>

					<services />

					<section class="p-home__oeuf">
						<div class="abs top">
							<div class="circle"></div>
							<div class="abs oeuf">
								<div class="wrapper-prllx" data-prllx=".8" data-p-no-mask>
									<div class="wrapper">
										<video class="lazy" autoplay muted playsinline loop>
											<source
												data-src="https://magency.s3.eu-central-1.amazonaws.com/Character+Loop_1.mp4"
												type="video/mp4">
											</source>
										</video>
										<div class="abs gradient"></div>
									</div>
								</div>

							</div>
						</div>
						<div class="title magency_text">
							<p>
								MULTIMEDIA AGENCY IS A PIONEER IN COMBINING ARTISTIC CREATIVITY WITH ADVANCED AI FOR
								TAILOR-MADE
								MARKETING SOLUTIONS. OUR PLATFORM EMPOWERS BUSINESSES WITH INTEGRATED, INNOVATIVE MEDIA
								STRATEGIES, ENHANCING BRAND VISIBILITY AND ENGAGEMENT. WITH MAGENCY, EXPERIENCE THE
								SYNERGY
								OF TECHNOLOGY AND CREATIVITY, DELIVERING POWERFUL RESULTS THROUGH SMART, EFFICIENT, AND
								CUSTOMIZED MARKETING APPROACHES THAT RESONATE WITH YOUR TARGET AUDIENCE."
							</p>
						</div>
						<ul class="facts">
							<li class="item">
								<h3 class="title" style="font-weight:bold">UNLEASH POTENTIAL</h3>
								<p class="p--s text">Embrace the power of AI-driven marketing solutions to unlock new
									growth
									and branding opportunities.</p>
							</li>
							<li class="item">
								<h3 class="title" style="font-weight:bold">EXPAND HORIZONS</h3>
								<p class="p--s text">Leverage our comprehensive, AI-enhanced creative services for
									impactful, data-informed campaigns.</p>
							</li>
							<li class="item">
								<h3 class="title" style="font-weight:bold">AMPLIFY YOUR BRAND</h3>
								<p class="p--s text">to-peer, acally focused on nano-micro influencers - ensuring the
									best
									engagement available.</p>
							</li>
						</ul>
					</section>

					<!--====== CLIENT LOGO PART START ======-->
					<section class="client-logo-area client-logo-one ">
						<!--======  Start Section Title Two ======-->
						<div class="section-title-two">
							<div class="container">
								<div class="row">
									<div class="col-12">
										<div class="content our_partner">
											<h1>Our Clients</h1>
										</div>
									</div>
								</div>
							</div>
							<!-- container -->
						</div>
						<!--====== End Section Title Two ======-->
					</section>
					<!--====== CLIENT LOGO PART ENDS ======-->

					<div class="container our_partner">

						<div class="d-flex justify-content-center flex-wrap">
							<div class="col-md-3 col-sm-6 col-lg-3 col-12 brand-parent" v-for="(item, index) in brands"
								:key="index">
								<img :src="item" alt="image" width="160" class="img-fluid">
							</div>
							<!-- Repeat for each image, adjusting the src as needed -->
							<!-- Add more <div class="col-md-3 col-sm-6">...</div> blocks for each additional image -->
						</div>
					</div>

					<div class="section-divider"></div>

					<teams />

					<!-- <contact /> -->
				</main>

				<div class="section-divider"></div>
				<appFooter />
			</div>
		</div>
	</div>

	<div class="wp-footer"/>
</template>

<script>
import appHeader from '@/components/appHeader.vue';
import appFooter from '@/components/appFooter.vue';
import services from '@/components/services.vue';
import teams from '@/components/teams.vue';
import contact from '@/components/contact.vue';
import hero from '@/components/hero.vue';
import ourWorks from '@/components/works.vue';
import Testimoneals from '@/components/testimoneals.vue';
import ready from '@/components/ready.vue';
import { Carousel, Navigation, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'


export default {
	name: 'HomeView',
	components: {
		appHeader, appFooter, services, teams, contact, hero, ourWorks, Testimoneals, ready, Carousel,
		Slide,
		Navigation,
	},
	data() {
		return {
			showComponent: false,
			brands: [
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/korek.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/bablyon.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/sardar_group.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/Toyota.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/lexus.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/tiptop.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/bnm.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/tornet.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/Torliga.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/superfast.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/bye_bye.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/cinekurd.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/SAS.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/auk_giving.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/kenza.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/chray_dusara.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/today_magazine.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/dil.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/infinix.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/kurdistan_tv.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/ordari.png",
				"https://magency.s3.eu-central-1.amazonaws.com/images/brands/soul_escape.png"

			],
			// carousel settings
			settings: {
				itemsToShow: 1,
				snapAlign: 'center',
			},
			// breakpoints are mobile first
			// any settings not specified will fallback to the carousel settings
			breakpoints: {
				// 700px and up
				700: {
					itemsToShow: 3.5,
					snapAlign: 'center',
				},
				// 1024 and up
				1024: {
					itemsToShow: 5,
					snapAlign: 'start',
				},
			},
		}
	},

	beforeRouteEnter(to, from, next) {
		if (from.name !== null && !sessionStorage.getItem('reloaded')) {
			sessionStorage.setItem('reloaded', 'true'); // Set the flag
			window.location.reload();
		} else {
			sessionStorage.removeItem('reloaded'); // Remove the flag for future navigations
			next();
		}
	},

	mounted() {
		// Set a timeout to show the component after 9 seconds
		setTimeout(() => {
			this.showComponent = true;
		}, 5000); // 9 seconds = 9,000 milliseconds
	}
}
</script>


<style>
.webgl-container {
	height: 100vh;
	position: relative;
}

.webgl-container2 {
	/* height: 1000px; */
	position: relative;
}

.webgl-container3 {
	height: 100vh;
	position: relative;
}

#cursor-canvas {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

#cursor-canvas2 {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

#cursor-canvas3 {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}



.brand-parent {
	align-self: center !important;
	margin-bottom: 20px !important;

	@media screen and (max-width: 768px) {
		margin-bottom: 60px !important;

	}
}

.banner-image {
	position: absolute;
	right: 0;
	top: 2100px;
	width: 300px;
	/* Adjust the width to a fixed size or percentage as needed */
	height: 100vh;
	/* Full height of the viewport */
	background-image: url('https://magency.s3.eu-central-1.amazonaws.com/images/circle_1.png');
	background-size: contain;
	/* This will ensure the image is not stretched */
	background-position: center;
	background-repeat: no-repeat;
	z-index: 1;
	/* Ensures the banner is above other content */
}

.banner-image2 {
	position: fixed;
	left: 0;
	top: 1500px;
	width: 200px;
	/* Adjust the width to a fixed size or percentage as needed */
	height: 100vh;
	/* Full height of the viewport */
	background-image: url('https://magency.s3.eu-central-1.amazonaws.com/images/side_icon.png');
	background-size: contain;
	/* This will ensure the image is not stretched */
	background-position: center;
	background-repeat: no-repeat;
	z-index: 999;
	/* Ensures the banner is above other content */
}

.section-divider {
	width: 400px !important;
	/* Full width of the container */
	height: 5px;
	margin: 0 auto;
	/* Height of the divider, adjust as needed */
	background-image: url('https://magency.s3.eu-central-1.amazonaws.com/images/divider.png');
	/* The path to your banner image */
	background-size: cover;
	/* Cover ensures that the banner covers the div area */
	background-repeat: no-repeat;
	background-position: center;
	/* Center the image within the div */
}

.our_partner {
	margin-bottom: 100px !important;
}

@media (max-width: 768px) {
	.banner-image {
		width: 215px !important;
	}


}

@media (max-width: 600px) {
	.banner-image {
		width: 100px !important;
		top: 1500px !important;
	}
}

.sticky {
	position: fixed;
	bottom: 3%;
	width: 100%;
	padding-left: 80%;
	z-index: 1000;
	display: none !important;
}
</style>